import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MaterialIcon from "material-icons-react";
import axios from "axios";
import config from "./../Config/index";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const sendData = (e) => {
    e.preventDefault();

    let userData = {
      email,
      password,
    };

    axios
      .post(`${config.backendURL}/auth`, userData, {
        withCredentials: true,
        headers: { "Access-Control-Allow-Credentials": true },
      })
      .then((res) => {
        if (res.status === 200) {
          navigate("/updatePrices");
        }
      })
      .catch((err) => {
        alert("Ошибка! " + err.message);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col></Col>
        <Col xs={12} md={4} lg={3}>
          <Form>
            <h3 className="m-4 text-center">Авторизация</h3>
            <Form.Group className="d-flex mb-3">
              <Form.Label className="mx-2 my-0 py-1">
                <MaterialIcon icon="mail_outline" size={30} />
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Введите email"
              ></Form.Control>
            </Form.Group>
            <Form.Group className="d-flex mb-3">
              <Form.Label className="mx-2 my-0 py-1">
                <MaterialIcon icon="lock" size={30} />
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Введите пароль"
              ></Form.Control>
            </Form.Group>
            <div className="text-center">
              <Button type="submit" className="btn-orange" onClick={sendData}>
                Отправить
              </Button>
            </div>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default About;
