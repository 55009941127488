import React, { Component } from "react";
import azsImg1 from "./../Images/azs-1.JPG";
import baseImg1 from "../Images/oilbase-1.jpg";
import azsImg3 from "../Images/azs-3.JPG";
import baseImg2 from "../Images/oilbase-2.jpg";

export default class slideShow extends Component {
  render() {
    return (
      <div className="d-flex flex-column">
        <div className="about-company-item">
          <img className="d-block w-100" src={azsImg1} alt="img1" />
          <p className="aboutCompany align-content-center">
            Компания «Alem Oil» занимается оптовой и розничной реализацией
            нефтепродуктов в Восточно-Казахстанской области. Компания была
            основана в 2005 году под брендом "Бухтарма Ойл" на базе
            имущественного комплекса "Бухтарминская нефтебаза". В течение 10 лет
            основным видом деятельности являлись прием, хранение, отпуск
            нефтепродуктов и их оптовая реализация.
          </p>
        </div>

        <div className="about-company-item">
          <img className="d-block w-100" src={baseImg1} alt="img2" />
          <p className="aboutCompany">
            С апреля 2015 года компания занимается развитием собственной сети
            автозаправочных станций. Отправной точкой стало введение в
            эксплуатацию новой АЗС в пос. Октябрьский. В 2016 и 2017 г были
            приобретены АЗС в г. Зыряновске и селе Улкен Нарын.{" "}
          </p>
        </div>

        <div className="about-company-item">
          <img className="d-block w-100" src={azsImg3} alt="img3" />
          <p className="aboutCompany">
            В 2017 году проведена реконструкция АЗС в г. Зыряновск, построена и
            введена в эксплуатацию АЗС в районе ст. Селезневка под брендом "Alem
            Oil". С августа 2018 года все АЗС ТОО "Бухтарма Ойл" были переданы
            под управление ТОО "Alem Oil".
          </p>
        </div>

        <div className="about-company-item">
          <img className="d-block w-100" src={baseImg2} alt="img4" />
          <p className="aboutCompany">
            На данный момент компания занимается дальнейшим развитием
            собственной сети автозаправочных станций в районе Алтай и
            Катон-Карагайском районе. Все строящиеся АЗС отвечают современным
            требованиям, а реализуемые нефтепродукты всегда отличаются высоким
            качеством, чему свидетельствуют многочисленные отзывы клиентов.
          </p>
        </div>
      </div>
    );
  }
}
